<template>
  <div class="bodyConfirmacao">
    <div class="msgError">
      <v-alert
        v-show="errorMsg != ''"
        type="warning"
        transition="scroll-x-reverse-transition"
        >{{ errorMsg }}</v-alert
      >
    </div>
    <p class="text-center titleConfirmacao">Confirmação</p>
    <div class="master-wrapper">
      <div class="circle-wrapper">
        <div class="circle-ativo">1</div>
      </div>
      <div class="circle-wrapper">
        <div class="circle-ativo">2</div>
      </div>
      <div class="circle-wrapper">
        <div class="circle">3</div>
      </div>
    </div>
    <p style="font-weight: 300">Valor negociado:</p>
    <div v-if="this.proposta == 'avista'">
      <p style="font-weight: bold">R$ {{ this.valorTotal }}</p>
    </div>
    <div v-if="this.proposta == 'parcelado'">
      <p style="font-weight: bold">R$ {{ this.valorEntrada }}</p>
      <p style="font-weight: bold">
        + {{ this.qtdeParcelas }}x {{ this.valorParcela }}
      </p>
    </div>

    <p style="font-weight: 300">no boleto</p>

    <p style="font-size: 19px; color: #1976d2" class="pointer" @click="alterar">
      alterar
    </p>
    <p style="margin: 25px 0px 16px 0px">Quando você quer pagar o boleto?</p>
    <div class="d-flex justify-center mb-3">
      <v-date-picker
        class="calendario"
        locale="pt-BR"
        v-model="picker"
        :max="dateMax"
        :min="dateMin"
      ></v-date-picker>
    </div>
    <p class="mt-6">Deseja receber o boleto via:</p>
    <div class="d-flex justify-center align-center mt-6 icons">
      <v-icon
        size="50px"
        :color="colorWhats"
        @click="onWhatsapp"
        v-model="metodoEnvio"
      >
        mdi-whatsapp
      </v-icon>
      <v-icon
        size="50px"
        :color="colorEmail"
        @click="onEmail"
        v-model="metodoEnvio"
      >
        mdi-email-outline
      </v-icon>
    </div>
    <div class="inputWhats">
      <v-expand-transition>
        <v-text-field
          v-show="expand"
          label="Digite seu telefone"
          style="font-size: 19px"
          v-model="tel"
          v-mask="['(##) #####-####', '(##) ####-####']"
        ></v-text-field>
      </v-expand-transition>
    </div>
    <div class="inputEmail">
      <v-expand-transition>
        <v-text-field
          style="font-size: 19px"
          v-show="expand2"
          label="Digite seu email"
          v-model="email"
        ></v-text-field>
      </v-expand-transition>
      <v-expand-transition>
        <v-text-field
          style="font-size: 19px"
          v-show="expand2"
          label="Digite seu telefone"
          v-mask="['(##) #####-####', '(##) ####-####']"
          v-model="tel"
        ></v-text-field>
      </v-expand-transition>
    </div>
    <v-btn
      color="primary"
      rounded
      large
      style="margin: 0 auto; display: block"
      class="mt-8"
      width="200"
      @click="propostaEncaminha"
      >Avançar</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "Confirmacao",
  data() {
    return {
      expand: false,
      expand2: false,
      tel: "",
      email: "",
      metodoEnvio: "",
      colorWhats: "grey darken-2",
      colorEmail: "grey darken-2",
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateMin: new Date().toISOString().slice(0, 10),
      date: new Date(),
      qtdeParcelas: null,
      valorEntrada: null,
      valorParcela: null,
      valorTotal: null,
      contrato: null,
      proposta: null,
      errorMsg: "",
    };
  },
  methods: {
    onWhatsapp() {
      this.metodoEnvio = "whatsapp";
      if (this.colorWhats == "green darken-2") {
        this.colorWhats = "grey darken-2";
      } else {
        this.colorWhats = "green darken-2";
        this.colorEmail = "grey darken-2";
      }
      if (this.expand2) {
        this.expand2 = false;
      }
      this.expand = !this.expand;
    },
    onEmail() {
      this.metodoEnvio = "email";
      if (this.colorEmail == "red darken-2") {
        this.colorEmail = "grey darken-2";
      } else {
        this.colorWhats = "grey darken-2";
        this.colorEmail = "red darken-2";
      }
      if (this.expand) {
        this.expand = false;
      }
      this.expand2 = !this.expand2;
    },
    alterar() {
      this.$router.push({
        name: "Propostas",
      });
    },
    propostaEncaminha() {
      let token = localStorage.getItem("_my_token");
      fetch(`${this.$url}/proposta/setProposta`, {
        method: "POST",
        body: JSON.stringify({
          jwt: token,
          valorTotal: this.valorTotal,
          valorEntrada: this.valorEntrada,
          valorParcela: this.valorParcela,
          qtdeParcelas: this.qtdeParcelas,
          vencimento: this.picker,
          metodoEnvio: this.metodoEnvio,
          telefone: this.tel,
          email: this.email,
          contrato: this.contrato,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.errorMsg = json.error;
          setTimeout(() => {
            this.errorMsg = "";
          }, 3000);
          localStorage.setItem("tel", this.tel);
          if (json.true == "Proposta inserida com sucesso") {
            this.$router.push({
              name: "PropostaEncaminhada",
            });
          }
        });
    },
  },
  mounted() {
    this.qtdeParcelas = localStorage.getItem("qtdeParcelas");
    this.valorEntrada = localStorage.getItem("valorEntrada");
    this.valorParcela = localStorage.getItem("valorParcela");
    this.valorTotal = localStorage.getItem("valorTotal");
    this.contrato = localStorage.getItem("contrato");
    this.proposta = localStorage.getItem("proposta");
  },
  computed: {
    dateMax() {
      let endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + 4
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
};
</script>

<style scoped>
.bodyConfirmacao {
  font-family: "Nunito", sans-serif;
  background-color: white;
  width: 600px;
  border-radius: 8px;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 25px;
}
.titleConfirmacao {
  font-size: 22px;
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}

.master-wrapper {
  width: 100%;
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 15px;
  margin-bottom: 10px;
}
.circle-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.circle-wrapper:before,
.circle-wrapper:after {
  content: "";
  position: absolute;
  display: block;
  background-color: rgb(201, 201, 201);
  height: 1px;
  width: 100%;
}
.circle-wrapper:before {
  left: 0;
}
.circle-wrapper:after {
  right: 0;
}
.circle-ativo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: dodgerblue;
  color: white;
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: 600;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 1px solid rgb(201, 201, 201);
  z-index: 1;
  text-align: center;
  line-height: 23px;
}
.calendario {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.inputEmail {
  width: 300px;
  display: block;
  margin: 30px auto 0px auto;
}
.inputWhats {
  width: 200px;
  display: block;
  margin: 30px auto 0px auto;
}

p {
  text-align: center;
  margin: 2px;
  font-size: 22px;
}

.icons {
  gap: 0px 15px;
  margin-bottom: 20px;
}
.msgError {
  position: absolute;
  z-index: 60;
  top: 80px;
  right: 25px;
}

@media (max-width: 600px) {
  .bodyConfirmacao {
    width: 95vw;
  }
  .calendario {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .msgError {
    width: 300px;
    right: 10px;
    top: 150px;
  }
}
@media (max-height: 670px) {
  .msgError {
    top: 290px;
  }
}
</style>