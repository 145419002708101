<template>
  <LayoutCliente>
    <Confirmacao />
  </LayoutCliente>
</template>

<script>
import LayoutCliente from "@/Layouts/LayoutCliente.vue";
import Confirmacao from "@/components/Cliente/Confirmacao.vue";
export default {
  components: { LayoutCliente, Confirmacao },
};
</script>

<style>
</style>